import React, { Component, Suspense, lazy } from "react";


import './styles/variables.css';
import './styles/App.css';
import NavBar from './components/NavBar'
import 'bootstrap/dist/css/bootstrap.css'
import './components/svg/NavLogo'
// import FAQ from './components/FAQ'
import NJSplash from './components/Splash'
// import CornerBoxMint from "./components/CornerBoxMint";
// import CharAccordion from "./components/CharAccordion";

// import Utility from "./components/Utility";
// import PageSwipe from "./components/PageSwipe"
// import Footer from "./components/Footer"

// import Team from "./components/Team"
// import Lore from "./components/Lore"
// import Roadmap from "./components/Roadmap"

import Scroll from 'react-scroll';

// import ETH from "./components/ETH_PricesLoader"
// import ImageConveyer from "./components/ImageConveyer"

// const CornerBoxMint = lazy(() => import('./components/CornerBoxMint'));
// const FAQ = lazy(() => import('./components/FAQ'));
// const CharAccordion = lazy(() => import('./components/CharAccordion'));
// const Utility = lazy(() => import('./components/Utility'));
// const PageSwipe = lazy(() => import('./components/PageSwipe'));
const Footer = lazy(() => import('./components/Footer'));
const Lore = lazy(() => import('./components/Lore'));
const Video = lazy(() => import('./components/Video'));
// const Roadmap = lazy(() => import('./components/Roadmap'));
const Team = lazy(() => import('./components/Team'));
//const ImageConveyer = lazy(() => import('./components/ImageConveyer'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
        
    }
   
}

  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

   

  };

  render() {

   

    return (

   

      <div className='App'>

       
     
        <div>
        <NavBar />
        </div>
        {/* <ETH/> */}

        <div className='content'>
        <NJSplash />
        <Suspense fallback={<div />}>
       
        
         
            {/* <div className="center-content-vert">


              <div className="textblock">
            <span className="xl">SUPPORTING THE ARTS HAS NEVER BEEN MORE LUCRATIVE.</span><br/><br />

            Your purchase of our NFTs funds the ongoing indie comic series of <span className="em">NIGHT JACKAL</span>, as well as a web3 publishing and distribution system for comics. We've done the hard part by completing the first <u>three issues</u>. This is where you come in! Your investment will ensure <u>future</u> issues are funded, allowing for the series to gain a wider audience - and <span className="em">GROWTH</span>. <br/><br/>
            
            Growth in the series means growth in interest for these NFTs! 

              </div>
              
          </div> */}
     
          

         {/* <ImageConveyer /> */}
        
         

        {/* <Scroll.Element name="nfts" className="element">
          
          <PageSwipe />

    

          <CharAccordion />

          <Utility />

        </Scroll.Element> */}

       
        

        {/* <Scroll.Element name="roadmap" className="element">

        <Roadmap />

        </Scroll.Element> */}

        <Scroll.Element name="lore" className="element">
          <Lore />
        </Scroll.Element>

        <Scroll.Element name="video" className="element video">
          <Video />
        </Scroll.Element>
  
  

        <Scroll.Element name="team" className="element">
          <Team />
        </Scroll.Element>

        {/* <Scroll.Element name="faq" className="element">
        <FAQ />
        </Scroll.Element> */}

        {/* <Scroll.Element name="cornerbox" className="element">
        <CornerBoxMint/>
         </Scroll.Element> */}
        
        <Footer />
      
    </Suspense>
      </div>
     
  </div>

      
    );
  }
}

export default App;
