import React, {useState} from 'react'
import '../styles/variables.css';
import '../styles/NavBar.css'
import {Navbar, Nav, Container} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons'
import {faTwitter, faDiscord} from '@fortawesome/free-brands-svg-icons'
import NavLogo from './svg/NavLogo'
//import {motion} from 'framer-motion/dist/es/index'
// import {motion} from 'framer-motion/dist/framer-motion'
import { motion } from "framer-motion"
// import Scroll, {scroller} from 'react-scroll';


function NavBarLite() {

    const [expanded, setExpanded] = useState(false)
    // const Link = Scroll.Link;

    function overrideToggle() {
        
        setExpanded(prevExpanded => !prevExpanded)
    }
 
    //todo: move logo to it's own component

    return (
       
        <motion.div
          initial={{y: -100}}
          animate={{y:0}}
          transition={{ y: { type: "spring", stiffness: 100}, type: "easeOut"}}
        >
           
           <Navbar 
           className="navbar"
           collapseOnSelect 
           //bg="dark" 
           variant="dark" 
           expand="lg"
           
           // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
           expanded={expanded} // only need to add this when configuring the nav to detect its expanded state
           onToggle={overrideToggle}
           >

            <Container className="navbar-container">

            
            
                <Navbar.Brand className="navbar-logo" href="/">
                        <motion.div
                         whileHover={{ scale: 1.1 }}
                         whileTap={{ scale: 0.9 }}
                        >
                            <NavLogo width="70px" height="70px"/>
                            
                        </motion.div>

                </Navbar.Brand>

                
                
                <Navbar.Toggle className="navbar-toggle" aria-controls="basic-navbar-nav">
                <div>
                    <FontAwesomeIcon 
                        icon={expanded ? faTimes : faBars} color="#FFF" size="2x" 
                    />
                </div>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav px-auto">
                    <Nav className="navbar-menu ml-auto px-5"> 
                   
                                <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                    href="/"
                                >HOME</Nav.Link>
                                </Nav.Item>
                                
                                
                    </Nav>
                     <Nav className="navbar-social">
                                <Nav.Item>
                                    <Nav.Link className="navbar-social-buttons"
                                        href="https://twitter.com/NightJackalGang" target="_blank"
                                    >
                                    <FontAwesomeIcon 
                                        icon={faTwitter} className="twitter" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="navbar-social-buttons"
                                    href="https://discord.gg/8f4YyBcD" target="_blank"
                                    >
                                        <span >
                                    <FontAwesomeIcon 
                                        icon={faDiscord}/>
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                                
                    </Nav>
                       
                   
         
                </Navbar.Collapse>

                
                
            </Container>

            
           
            </Navbar> 
       
            
        </motion.div>
        
       
    )
}

export default NavBarLite
