import React from 'react'
// import {motion} from 'framer-motion/dist/framer-motion'
import { motion } from "framer-motion"

function NavLogo(props) {

    return (
        <motion.div
            initial={{rotateY: 180}}
            animate={{rotateY:[180, 0]}}
            transition={{duration:1, delay: .3}}
        >
          
            <motion.svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.8 248.13" fill="none"
            >
                <defs>
                <path id="a" d="M346.77,334.4,272.5,518.59c-5.34-7.28-10.56-14.23-15.58-21.33a7.45,7.45,0,0,0-5.27-3.2c-8.59-1.49-17.14-3.22-25.75-4.63-1.86-.31-3.91.27-5.84.62-8,1.44-15.95,2.89-23.9,4.48a4.85,4.85,0,0,0-2.55,1.86c-5.27,7.07-10.46,14.2-15.69,21.32-.14.19-.31.37-.68.8L103,334.33l.29-.18,12,10.33,36.23,31.15C157,380.37,162.6,385,168,389.89c2.08,1.87,4.61,1.68,7,2.13,11.61,2.19,23.25,4.21,34.88,6.3,4.39.78,8.75,1.85,13.17,2.24a33.57,33.57,0,0,0,8.61-.81c10.18-1.74,20.35-3.61,30.52-5.43,5.72-1,11.45-2,17.17-3.07a4.44,4.44,0,0,0,1.93-1q28.32-24.33,56.6-48.68c2.87-2.47,5.76-4.92,8.64-7.38ZM199,453.91l8.36-14.47-42-10-.14.38Zm85.59-24.11-.12-.39-42,10,8.38,14.45Z
                M203.43,511.81c2.69,3.4,5.29,6.63,7.83,9.9,3.3,4.24,3.28,4.2,8.68,4.1,4.35-.07,8.7-.07,13,.08a4,4,0,0,0,3.71-1.8c2.9-3.8,5.89-7.54,8.85-11.3.22-.28.48-.53.85-.93l2,3c3.35,5.15,6.66,10.33,10.06,15.44a2.22,2.22,0,0,1,0,2.95q-14.61,21.13-29.09,42.37c-1.41,2.07-2.59,4.3-3.89,6.44a.68.68,0,0,1-.52.19c-.18,0-.45,0-.53-.17-1.26-2.1-2.39-4.28-3.77-6.3q-14.44-21.13-29-42.2a2.69,2.69,0,0,1-.06-3.59c3.66-5.46,7.19-11,10.77-16.53C202.62,513,203,512.49,203.43,511.81Z" transform="translate(-102.97 -334.15)" 
              />
             
                <clipPath id="b">
                 <use xlinkHref="#a" />
                </clipPath>
          
                <motion.linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="100%" 
                gradientTransform="rotate(0)"
                animate={{gradientTransform: ["rotate(0)", "rotate(360)"]}}
                transition={{repeat:Infinity, duration: 20}}
                >
                
            <stop
              stopColor="#FFFFFF20"
              offset="5%"
            />
            <stop
              stopColor="#00000040"
              offset="25%"
            />
            <stop
              stopColor="#FFFFFF20"
              offset="95%"
            />
            </motion.linearGradient>
            </defs>
            <use
          fill="url(#linear)"
          stroke="transparent"
          strokeWidth="0"
          clipPath="url(#b)"
          xlinkHref="#a"
          
        />
                
                <motion.path id="njlogo-head" fill='#FFFFFF' d="M346.77,334.4,272.5,518.59c-5.34-7.28-10.56-14.23-15.58-21.33a7.45,7.45,0,0,0-5.27-3.2c-8.59-1.49-17.14-3.22-25.75-4.63-1.86-.31-3.91.27-5.84.62-8,1.44-15.95,2.89-23.9,4.48a4.85,4.85,0,0,0-2.55,1.86c-5.27,7.07-10.46,14.2-15.69,21.32-.14.19-.31.37-.68.8L103,334.33l.29-.18,12,10.33,36.23,31.15C157,380.37,162.6,385,168,389.89c2.08,1.87,4.61,1.68,7,2.13,11.61,2.19,23.25,4.21,34.88,6.3,4.39.78,8.75,1.85,13.17,2.24a33.57,33.57,0,0,0,8.61-.81c10.18-1.74,20.35-3.61,30.52-5.43,5.72-1,11.45-2,17.17-3.07a4.44,4.44,0,0,0,1.93-1q28.32-24.33,56.6-48.68c2.87-2.47,5.76-4.92,8.64-7.38ZM199,453.91l8.36-14.47-42-10-.14.38Zm85.59-24.11-.12-.39-42,10,8.38,14.45Z" transform="translate(-102.97 -334.15)" 
                stroke="white" 
                initial={{ pathLength: 0, fill: "#ffffff00"}}
                animate={{ pathLength: [0, 1, 0], fill: ["#ffffff00", "#ffffff00", "#ffffff40"]}}
                transition={{ duration: 8, ease: "easeOut", delay: 0.8}}
                />
                <motion.path id="njlogo-jaw" fill='#FFFFFF' d="M203.43,511.81c2.69,3.4,5.29,6.63,7.83,9.9,3.3,4.24,3.28,4.2,8.68,4.1,4.35-.07,8.7-.07,13,.08a4,4,0,0,0,3.71-1.8c2.9-3.8,5.89-7.54,8.85-11.3.22-.28.48-.53.85-.93l2,3c3.35,5.15,6.66,10.33,10.06,15.44a2.22,2.22,0,0,1,0,2.95q-14.61,21.13-29.09,42.37c-1.41,2.07-2.59,4.3-3.89,6.44a.68.68,0,0,1-.52.19c-.18,0-.45,0-.53-.17-1.26-2.1-2.39-4.28-3.77-6.3q-14.44-21.13-29-42.2a2.69,2.69,0,0,1-.06-3.59c3.66-5.46,7.19-11,10.77-16.53C202.62,513,203,512.49,203.43,511.81Z" transform="translate(-102.97 -334.15)" 
                stroke="white"  
                initial={{ pathLength: 0, fill: "#ffffff00" }}
                animate={{ pathLength: [0, 1, 0], fill: ["#ffffff00", "#ffffff00", "#ffffff40"]}}
                transition={{ duration: 8, ease: "easeOut", delay: 1}}
                />

           
            </motion.svg>

            
        
            {/* <img width="60px" height="auto" src={Logo}  alt="logo" />  */}
        </motion.div>
    )
}



export default NavLogo
