import React from 'react'

export const Box = ({ children }) => (
	<div
	  style={{
		padding: '80px 60px',
		background: 'black',
		bottom: 0,
		width: '100%',
		height: 'auto',
		maxHeight: '-webkit-fill-available',
		display: 'flex',
	  }}
	>
	  {children}
	</div>
  );
  
  export const MediaHorizontalContainer = ({ children }) => (
	<div
	  style={{
		display: 'inherit',
		width: 'inherit',
	  }}
	>
	  {children}
	</div>
  );
  
  export const TabContainer = ({ children }) => (
	<div
	  style={{
		display: 'inline-block',
		float: 'left',
	  }}
	>
	  {children}
	</div>
  );
  
  export const Tab = ({ children }) => (
	<div
	  style={{
		padding: '20px 0 20px 0',
		width: '100%',
		height: 'auto',
	  }}
	>
	  {children}
	</div>
  );
  
  export const RowContainer = ({ children }) => (
	<div
	  style={{
		display: 'flex',
		flexDirection: 'column',
		margin: '0 0 0 auto',
		height: '100%',
	  }}
	>
	  {children}
	</div>
  );
  
  export const Column = ({ children }) => (
	<div
	  style={{
		display: 'grid',
		gridRow: 1,
		textAlign: 'right',
		marginLeft: '10px',
		minWidth: '80px',
		height: 'inherit',
	  }}
	>
	  {children}
	</div>
  );
  
  export const Row = ({ children }) => (
	<div
	  style={{
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(185px, 1fr))',
		gridGap: '10px',
	  }}
	>
	  {children}
	</div>
  );
  
  export const FooterLink = ({ href, children }) => (

	<a
	  href={href}
	  style={{
		color: 'var(--secondary-text-color)',
		marginBottom: '10px',
		fontSize: 'x-small',
		textDecoration: 'none',
		cursor: 'pointer',
	  }}
	>
	  {children}
	</a>

  );
  
  export const FooterLinkBold = ({ href, children }) => (

	<a
	  href={href}
	  style={{
		color: 'var(--primary-text-color)',
		fontSize: 'medium',
		fontWeight: 'normal',
		textDecoration: 'none',
		cursor: 'pointer',
	  }}
	>
	  {children}
	</a>

  );
  
  export const FooterLinkIcon = ({ href, children }) => (

	<a
	  href={href}
	  style={{
		color: 'var(--primary-text-color)',
		padding: '0 5% 0 0',
		cursor: 'pointer',
	  }}
	>
	  {children}
	</a>

  );
  
  export const Heading = ({ children }) => (
	<p
	  style={{
		fontSize: 'small',
		color: 'var(--primary-text-color)',
		marginBottom: '10px',
		fontWeight: 'bold',
	  }}
	>
	  {children}
	</p>
  );
  
  export const Copyright = ({ children }) => (
	<p
	  style={{
		fontSize: 'xx-small',
		color: 'var(--minimized-secondary-text-color)',
		fontWeight: 'lighter',
		textAlign: 'center',
		marginTop: '-30px',
	  }}
	>
	  {children}
	</p>
  );
  
  export const LargeText = ({ children }) => (
	<p
	  style={{
		fontSize: 'xx-large',
		color: 'var(--emphasied-text-color)',
		marginBottom: '10px',
		fontWeight: 'bold',
		letterSpacing: '0px',
		lineHeight: '.9',
		wordSpacing: '0px',
	  }}
	>
	  {children}
	</p>
  );
  
  export const LessLargeText = ({ children }) => (
	<p
	  style={{
		fontSize: 'x-large',
		color: 'var(--emphasied-text-color)',
		marginBottom: '10px',
		fontWeight: 'bold',
		letterSpacing: '0px',
		lineHeight: '.9',
		wordSpacing: '0px',
	  }}
	>
	  {children}
	</p>
  );
  
  export const MediumText = ({ children }) => (
	<p
	  style={{
		fontSize: 'medium',
		color: 'var(--minimized-text-color)',
		marginBottom: '10px',
		fontWeight: 100,
		letterSpacing: '0px',
		lineHeight: 1,
		wordSpacing: '0px',
	  }}
	>
	  {children}
	</p>
  );
  
  export const Spacer = ({ children }) => (
	<div
	  style={{
		margin: '2%',
	  }}
	>
	  {children}
	</div>
  );
  