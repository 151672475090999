import React from 'react';
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
// import Terms from "./Terms"
// import Privacy from "./Privacy"
import FourOhFour from "./404"
// import Read from "./Read"
// import Mint from "./Mint"
// import Madmen from "./Madmen"
// import Provenance from "./Provenance"
// import Calendar from "./Calendar.js";

import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";

// ReactDOM.render(<App />, document.getElementById('root'));

const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement).render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      {/* <Route path="terms" element={<Terms />} />
      <Route path="privacy-policy" element={<Privacy />} /> */}
      <Route path="*" element={<FourOhFour />} />
      
{/* 
      <Route path="read" element={<Read />} />
      <Route path="mint" element={<Mint />} />
      <Route path="madmen" element={<Madmen />} /> */}
      {/* <Route path="provenance" element={<Provenance />} /> */}
      {/* <Route path="calendar" element={<Calendar />} /> */}
    </Routes>
  </BrowserRouter>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
