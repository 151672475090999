import NavBarLite from './components/NavBarLite'
import React, { Component } from 'react';
import "./styles/404.css"
import Footer from "./components/Footer"

export default class FourOhFour extends Component {
  render() {
    return <div>

        <NavBarLite />

        <div>
          <div className="lost-container center-content-vert">

          <div className="lost-text">

          <h1>404</h1>
          <span className="textblock">You look lost...</span>

          </div>

          </div>
      </div>
    <Footer/>
    </div>;
  }
}
