import React from "react";
// import Obfuscate from 'react-obfuscate';


//import ETH from "../components/ETH_PricesLoader"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXTwitter} from '@fortawesome/free-brands-svg-icons'

import {
Box,
MediaHorizontalContainer,
TabContainer,
Tab,
RowContainer,
Row,
Column,
// FooterLink,
FooterLinkIcon,
// Heading,
Copyright,
LargeText,
// LessLargeText,
MediumText,
Spacer
} from "./FooterStyles";


const Footer = () => {
return (
    <>
	<Box>
    <MediaHorizontalContainer>
   <TabContainer>
    
     <Spacer />
     <Tab style={{borderTop: "solid", borderColor: "var(--theme-color-1)", borderSize: "5px"}}>
     <LargeText>NIGHT JACKAL COMIC BOOK SERIES</LargeText>
     {/* <LessLargeText>WEB3 + STORY</LessLargeText> */}
     <MediumText>You owe it to yourself to dive into this adventure.</MediumText>
     </Tab>

      
     <Tab>

     <FooterLinkIcon href="https://twitter.com/NightJackalGang" target="_blank"><FontAwesomeIcon icon={faXTwitter} /></FooterLinkIcon>
     {/* <FooterLinkIcon href="https://discord.gg/N5f2XyBgtu" target="_blank"><FontAwesomeIcon icon={faDiscord} /></FooterLinkIcon> */}


     </Tab>
     </TabContainer>
    </MediaHorizontalContainer>


    <MediaHorizontalContainer>
	<RowContainer >
		<Row >
		<Column >
			{/* <Heading>That other important stuff...</Heading>
		
			<FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
		
			<FooterLink href="/terms">Terms of Service</FooterLink>   
			
      */}
            
		</Column>
		
       
		</Row>
        <Spacer />
        {/* <Tab style={{borderTop: "solid", borderColor: "var(--theme-color-1)", borderSize: "5px"}}>
            <ETH />
        </Tab> */}
		
         
	</RowContainer>
    </MediaHorizontalContainer>
    

    {/* <TabContainer>
        <Tab>
     <LargeText>CURIOUS?</LargeText>
     <LargeText>GET IN TOUCH</LargeText>
     <MediumText>We can't promise a reply but we'd love to hear your thoughts.</MediumText>
     </Tab>
    </TabContainer> */}
 
	</Box>

    <Copyright>
        <span className="ft-copyright">Copyright © {new Date().getFullYear()} Nothing Rhymes With Entertainment, LLC. All Rights Reserved. </span>
			<br/><span className="opacity-25">v.0.3.1</span>

      </Copyright>
      </>
     
    
);
};
export default Footer;
